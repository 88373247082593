import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				 Usługi sprzątania ShineBright
			</title>
			<meta name={"description"} content={"Spraw, by Twoja przestrzeń lśniła bez wysiłku"} />
			<meta property={"og:title"} content={" Usługi sprzątania ShineBright"} />
			<meta property={"og:description"} content={"Spraw, by Twoja przestrzeń lśniła bez wysiłku"} />
			<meta property={"og:image"} content={"https://fleximflex.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fleximflex.com/img/325452345.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fleximflex.com/img/325452345.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fleximflex.com/img/325452345.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fleximflex.com/img/325452345.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fleximflex.com/img/325452345.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fleximflex.com/img/325452345.png"} />
			<meta name={"msapplication-TileColor"} content={"https://fleximflex.com/img/325452345.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-primary" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Skontaktuj się z nami{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
				Gotowy, aby Twój dom lśnił? Skontaktuj się z nami i porozmawiajmy o tym, jak możemy wprowadzić nowy poziom czystości do Twojej przestrzeni.
				</Text>
				<Link
					href="mailto:blank?info@fleximflex.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@fleximflex.com
				</Link>
				<Link
					href="tel:+48 22 888 4140"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+48 22 888 4140
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Senatorska 2, 00-075 Warszawa, Polska
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});